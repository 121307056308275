
import { defineComponent, onMounted, ref, computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { dateFormat } from "@/utils/format";
import { gaEvent } from "@/utils/format";
import { reboundDevice } from "@/api/devices";
import { icloudRebound } from "@/api/ios/index";
import reBoundList from "./tabItem/reBoundList.vue";
import noticeDialog from "./tabItem/noticeDialog.vue";
import { ElMessage } from "element-plus/lib/components";
import { useI18n } from "vue-i18n";
import { refreshTab } from "@/utils/refresh";
export default defineComponent({
  name: "GuidePage",
  components: {
    reBoundList,
    noticeDialog,
  },
  setup() {
    const store = useStore();
    let router = useRouter();
    const { t } = useI18n();
    onMounted(() => {
      gaEvent("Binddevice_Guide_View", {
        event_category: "AddDevice",
        event_label: "None",
      });
    });

    // 过期时间
    const subscripts = computed(() => {
      return store.state.subscripts?.expires_at;
    });

    const expiredDevices = computed(() => {
      return store.state.expiredDevices;
    });

    // 点击详情
    const goToDetail = () => {
      gaEvent("Binddevice_Guide", {
        event_category: "AddDevice",
        event_label: "None",
      });
      // 详细指引的跳转链接
      window.open(
        "https://cbs.famikeep.com/go?pid=5530&a=c1&v=2.1.0",
        "_blank"
      );
    };

    // 控制过期设备列表dialog的显隐
    const showList = ref(false);
    // 控制换绑的dialog
    const showReboundDialog = ref(false);
    // 换绑失败弹窗的出现
    const showReboundFailed = ref(false);
    // 换绑中
    const showRebounding = ref(false);
    // 控制换绑dialog消失
    const closeReboundDialog = () => {
      showReboundDialog.value = false;
    };

    const closeReboundFailed = () => {
      showReboundFailed.value = false;
    };

    // 去换绑
    const goToRebind = () => {
      gaEvent("ChangeBind_Guide", {
        event_category: "AddDevice",
        event_label: "None",
      });
      if (expiredDevices.value.length > 0) {
        showList.value = true;
      } else {
        ElMessage({
          message: t("dashboard.noPlace"),
          type: "warning",
        });
        gaEvent("NoBind_Pop", {
          event_category: "AddDevice",
          event_label: "None",
        });
      }
    };

    // 关闭dialog
    const closeList = () => {
      showList.value = false;
    };

    const pickId = ref(null);
    const pickName = ref(null);

    // 确定选中某一id
    const reBinding = (id: any, name: any) => {
      if (id !== null && name !== null) {
        closeList();
        pickId.value = id;
        pickName.value = name;
        showReboundDialog.value = true;
      }
      // else {
      //   ElMessage({
      //     message: "Please choose a device",
      //     type: "warning"
      //   })
      // }
    };

    // 点击确定换绑
    const confirmRebind = async () => {
      showReboundDialog.value = false;
      showReboundFailed.value = false;
      // 等网络请求完毕就弹窗消失
      showRebounding.value = true;
      percent.value = 0;
      updatePercent();
      try {
        // 发送换绑网络请求
        const params = {
          device_id: pickId.value,
        };
        let res = await reboundDevice(params);
        if (res.data.code === 200 && res.data.message === "OK") {
          await refreshTab();
          isSuccess.value = true;
          showRebounding.value = false;
          gaEvent("ChangeBind_Success", {
            event_category: "Dashboard_Function",
            event_label: "Guide",
          });
          // 换绑成功应该选中该设备并跳转回该设备的首页
          router.push({ path: "/home" });
          ElMessage.success(t("dashboard.ReboundSuc"));
        }
      } catch (err) {
        // 换绑失败
        showRebounding.value = false;
        showReboundFailed.value = true;
        gaEvent("ChangeBind_Fail", {
          event_category: "Dashboard_Function",
          event_label: "Guide",
        });
      }
    };

    // 进度条的进度，取值范围0~100
    let percent = ref(0);
    // 任务完成状态：true：完成 false：未完成
    let isSuccess = ref(false);
    // 更新进度
    const updatePercent = () => {
      setTimeout(() => {
        // step为随机数
        let step = Math.ceil(Math.random() * 20);
        if (isSuccess.value) {
          // 最后一步直接走到100
          step = 100 - percent.value;
        } else {
          // 如果进度走到99了，任务还没有成功，就每次都加上0，相当于卡在不动
          step = percent.value + step >= 99 ? 0 : step;
        }
        percent.value += step;
        // 没有到100，就一直调用该函数
        if (percent.value < 100) {
          updatePercent();
        } else {
        }
      }, 500);
    };


    // 禁用app
    // const pickList:any = []

    // const appList = [
    //   {
    //     icon: require("@/assets/img/Launcher.svg"),
    //     name:'58同城',
    //     id:1,
    //   },
    //   {
    //     icon: require("@/assets/img/Launcher.svg"),
    //     name:'Facebook',
    //     id:2,
    //   },
    //   {
    //     icon: require("@/assets/img/Launcher.svg"),
    //     name:'Class for Android',
    //     id:3,
    //   },
    //   {
    //     icon: require("@/assets/img/Launcher.svg"),
    //     name:'Google',
    //     id:4,
    //   },
    // ]

    // const activeIndex = ref()
    // const itemClick = (item:any) => {
    //   activeIndex.value = item.id
    //   pickList.push(item)
    // }

    return {
      percent,
      subscripts,
      showList,
      showReboundDialog,
      showReboundFailed,
      showRebounding,
      pickId,
      pickName,
      closeReboundDialog,
      closeReboundFailed,
      confirmRebind,
      dateFormat,
      goToDetail,
      goToRebind,
      closeList,
      reBinding,


      // 禁用app
      // appList,
      // pickList,
      // activeIndex,
      // itemClick,
    };
  },
});
